import React, { FC, Fragment } from "react";
import { TMainContent } from "#types/jobs";
import { useIsWidgetToApply } from "@hooks";
import { EWidgetType } from "@enums";
import GlobalFooter from "./globalFooter/component";

type Props = {
  type: TMainContent;
};

const FooterByType: FC<Props> = ({ type }) => {
  const isWidgetIframe = useIsWidgetToApply(EWidgetType.iframe);

  if (isWidgetIframe) return <Fragment />;

  switch (type) {
    case "landing":
    case "jobSite":
    case "referred":
    case "detail":
    case "general":
    case "searchHit":
    case "landingDetail":
      return <Fragment />;
    default:
      return <GlobalFooter />;
  }
};

export default FooterByType;
