import { THeaderItem } from "#types/schemas";
import { fullBasePath } from "@constants/environment.constant";

export const HEADER_LANDING: THeaderItem[] = [
  {
    tag: "secondOptionName",
    url: "routes:landing",
    baseUrl: `${fullBasePath}`,
    icon: "las la-home"
  },
  {
    tag: "thirdOptionName",
    url: "routes:jobsByCompany",
    baseUrl: `${fullBasePath}`,
    icon: "las la-briefcase"
  }
];
