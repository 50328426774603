import { language } from "@constants/environment.constant";

type returnProps = {
  month: string;
  year: number;
  day: number;
};

export const getCurrentDayMonthAndYear = (): returnProps => {
  const date = new Date();
  const day = date.getDate();
  const month = date.toLocaleString(language, { month: "long" });
  const year = date.getFullYear();
  return { day, month, year };
};
