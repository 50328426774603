import { TFunction } from "next-i18next";
import { formatDistanceToNow, parseISO } from "date-fns";
import * as Locales from "date-fns/locale";
import { language } from "@constants/environment.constant";

const lang = new Intl.Locale(language).language;

export const getFormatDistanceToNow = (date: string, t: TFunction) => {
  if (!date) return "";
  const fromNow = formatDistanceToNow(parseISO(date), { locale: Locales[lang as keyof typeof Locales] }).replace(
    t<string>("jobOffers:formatted.remove"),
    ""
  );
  return t<string>("jobOffers:formatted.datesTo", { fromNow });
};
