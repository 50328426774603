export const generateBreadCrumbs = (breadCrumbText: string) => {
  return breadCrumbText.split("/").filter((link) => link.trim() !== "");
};

export const generateBreadCrumbUrl = (breadCrumbList: string[], baseUrl: string) => {
  return breadCrumbList.map((_, index) => {
    const pathSegments = breadCrumbList.slice(0, index + 1);
    const url = `${baseUrl}${pathSegments.join("/")}`.replace(/\s+/g, "-");
    return url;
  });
};

export const formatBreadcrumbText = (breadCrumbFromServer: string[]): string => {
  return `/${breadCrumbFromServer.join("/")}`;
};
