import React, { useContext } from "react";
import { MagnetoUIProvider } from "magneto365.ui";
import { EDeviceTypes } from "@enums/filter.core.enum";
import MagnetoLoginHeader from "./magnetoLoginHeader";
import MagnetoLogoutHeader from "./magnetoLogoutHeader";
import { AuthContext } from "@contextauth.context";
import withCandidateHoc from "@componentscandidate/hoc";

type TProps = {
  device: EDeviceTypes;
};

const MagnetoUIHeader: React.FC<TProps> = ({ device }) => {
  const { isAuthenticated } = useContext(AuthContext);

  return (
    <MagnetoUIProvider device={device}>
      {isAuthenticated ? <MagnetoLoginHeader /> : <MagnetoLogoutHeader />}
      <div id="magneto-signup-v2-modal" className="magneto-signup-v2-modal" data-button-create="no" />
    </MagnetoUIProvider>
  );
};

export default withCandidateHoc(MagnetoUIHeader);
