import React, { Fragment, useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { useRouter } from "next/router";
import { AuthContext, CandidateContext, JobsContext } from "@context/auth.context";
import { TagManagerRepository, WidgetRepository } from "@repositories/index";
import { jsonDataLayer, viewItemListSectionFromRoute } from "@utils/googleTagManager";
import { TFunction } from "next-i18next";
import { Button } from "antd";
import profileSubject, { ProfileSubject } from "@classesprofileSubject.class";
import { useIsWidgetToApply } from "@hooks";
import { EQueryAction, EEventsGA4, EQueryParams, EViewItemListName, EWidgetType } from "@enums";
import { loadSdk, loadStyle } from "@utils/candidate";
import { applyWithProfileFullClosure } from "@utils/signUp";
import { linkToShare } from "@utils/jobOffers";
import { fillUrlUtms, getQueyParams } from "@utils/url";
import { ProfileConcreteObserver } from "@classesprofileConcreteObserver.class";
import styles from "./applyButton.module.scss";
import classNames from "classnames";
import warningIcon from "@public/assets/icons/warning.svg";
import Image from "next/image";

type Props = {
  t: TFunction;
  setJobDetailAction: (jobId: number | null, action?: EQueryAction) => void;
  jobOfferIndex?: number;
  position?: number;
};

const sessionStorageCheck = () => {
  const currentAction = window.sessionStorage.getItem("onCompleteActionJob");
  window.sessionStorage.removeItem("onCompleteActionJob");
  profileSubject.updateAction(currentAction);
};

const applyWithProfileFull = applyWithProfileFullClosure();

export const ApplyButton: React.FC<Props> = ({ t, setJobDetailAction, jobOfferIndex, position }) => {
  const authContext = useContext(AuthContext);
  const jobContext = useContext(JobsContext);
  const candidateContext = useContext(CandidateContext);
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const btnWithProfileFull = useRef<HTMLButtonElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const router = useRouter();
  const isWidgetIframe = useIsWidgetToApply(EWidgetType.iframe);
  const nextRouter = useRouter();
  const { query } = nextRouter;
  const querySearch = getQueyParams("q", query);

  useEffect(() => {
    intervalRef.current && clearInterval(intervalRef.current);
    intervalRef.current = setInterval(sessionStorageCheck, 400);
    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [candidateContext.availableToApply]);

  useEffect(() => {
    if (jobContext.jobDetailAction !== EQueryAction.apply || !btnWithProfileFull?.current) return;
    const slug = router.query?.jobSlug as string;
    if (slug !== applyWithProfileFull.slug()) applyWithProfileFull.clear();
    applyWithProfileFull(btnWithProfileFull.current, slug);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [router.query?.jobSlug]);

  const withIsWidgetIframe = useMemo(() => {
    if (!jobContext.selectedJob) return <Fragment />;
    const detailUrl = new URL(
      fillUrlUtms(linkToShare(t("routes:jobsView", { jobSlug: jobContext.selectedJob.jobSlug })), router.query)
    );
    detailUrl.searchParams.set(EQueryParams.action, EQueryAction.apply);
    return (
      <a
        className={classNames(styles["application-button"], styles["apply-btn"])}
        href={detailUrl.toString()}
        target="_blank"
        rel="noopener noreferrer"
      >
        {t<string>("jobOffers:actions.apply")}
      </a>
    );
  }, [jobContext.selectedJob, router, t]);

  const withoutAuth = useMemo(() => {
    if (!jobContext.selectedJob || authContext.isAuthenticated) return <React.Fragment />;
    const onClick = () => {
      if (!jobContext.selectedJob) return;
      setJobDetailAction(jobContext.selectedJob.id);
      TagManagerRepository.addToCartV4(
        jsonDataLayer(
          [jobContext.selectedJob],
          EViewItemListName.generalOffers.replace(
            "{query}",
            querySearch ? (querySearch as string) : EViewItemListName.enColombia
          ),
          viewItemListSectionFromRoute(nextRouter, !!jobContext.selectedJob),
          EEventsGA4.addToCart,
          { indexOrigin: (jobOfferIndex as number) + 1, position }
        )
      );
    };
    return (
      <button type="button" className={classNames(styles["application-button"], styles["apply-btn"])} onClick={onClick}>
        {t<string>("jobOffers:actions.apply")}
      </button>
    );
  }, [
    authContext.isAuthenticated,
    jobContext.selectedJob,
    jobOfferIndex,
    nextRouter,
    position,
    querySearch,
    setJobDetailAction,
    t
  ]);

  const withoutProfileFull = useMemo(() => {
    if (candidateContext.availableToApply || !authContext.isAuthenticated) return <React.Fragment />;
    const onClick = () => {
      if (!jobContext.selectedJob) return;
      TagManagerRepository.addToCartV4(
        jsonDataLayer(
          [jobContext.selectedJob],
          EViewItemListName.generalOffers.replace(
            "{query}",
            querySearch ? (querySearch as string) : EViewItemListName.enColombia
          ),
          viewItemListSectionFromRoute(nextRouter, !!jobContext.selectedJob),
          EEventsGA4.addToCart,
          { indexOrigin: (jobOfferIndex as number) + 1, position }
        )
      );
      const profileAppRoot = window.document.querySelector(".magneto-profile-v2-modal");
      sessionStorage.setItem("hasQuestionnaire", String(jobContext.selectedJob.hasPrefilterQuestionnaire));
      setLoading(true);
      profileAppRoot?.setAttribute("data-modal-status", "open");
      WidgetRepository.getProfileScripts().then((response) =>
        loadStyle("magneto.ui.profile", response.payloadStyle).then(() =>
          loadSdk("magneto.sdk.profile", response.payloadScript).then(() => setLoading(false))
        )
      );
    };

    const currentSearch = new URL(window.location.toString());
    const action = currentSearch.searchParams.get("action");
    if (action === "apply") {
      currentSearch.searchParams.delete("action");
      const query = currentSearch.toString();
      window.history.replaceState(null, document.title, query.toString());
      if (!jobContext.selectedJob?.isInternal) setTimeout(onClick, 600);
    }

    return (
      <React.Fragment>
        <div className="magneto-profile-v2-modal" />
        <Button
          loading={loading}
          className={classNames(styles["application-button"], styles["apply-btn"])}
          onClick={onClick}
        >
          {t<string>("jobOffers:actions.apply")}
        </Button>
      </React.Fragment>
    );
  }, [
    candidateContext.availableToApply,
    authContext.isAuthenticated,
    loading,
    t,
    jobContext.selectedJob,
    querySearch,
    nextRouter,
    jobOfferIndex,
    position
  ]);

  const withProfileFull = useMemo(() => {
    if (!jobContext.selectedJob || !authContext.isAuthenticated) return <React.Fragment />;
    const onClick = () => {
      if (!jobContext.selectedJob) return;
      TagManagerRepository.addToCartV4(
        jsonDataLayer(
          [jobContext.selectedJob],
          EViewItemListName.generalOffers.replace(
            "{query}",
            querySearch ? (querySearch as string) : EViewItemListName.enColombia
          ),
          viewItemListSectionFromRoute(nextRouter, !!jobContext.selectedJob),
          EEventsGA4.addToCart,
          { indexOrigin: (jobOfferIndex as number) + 1, position }
        )
      );
      if (!jobContext.selectedJob) return;
      if (jobContext.selectedJob.hasPrefilterQuestionnaire) {
        setJobDetailAction(jobContext.selectedJob.id);
        return;
      }
      setJobDetailAction(jobContext.selectedJob.id);
    };
    return (
      <button
        ref={btnWithProfileFull}
        type="button"
        className={classNames(styles["application-button"], styles["apply-btn"])}
        onClick={onClick}
      >
        {t<string>("jobOffers:actions.apply")}
      </button>
    );
  }, [
    authContext.isAuthenticated,
    jobContext.selectedJob,
    jobOfferIndex,
    nextRouter,
    position,
    querySearch,
    setJobDetailAction,
    t
  ]);

  const forInternalWithAuth = useMemo(() => {
    if (!jobContext.selectedJob?.isInternal) return <Fragment />;
    const hasLinkup = candidateContext.originsAndLinkups?.companyLinkups.includes(
      jobContext.selectedJob.mainCompanySlug
    );
    if (hasLinkup && candidateContext.availableToApply) return withProfileFull;
    if (hasLinkup && !candidateContext.availableToApply) return withoutProfileFull;

    return (
      <div className={styles["internal-msg"]}>
        <Image src={warningIcon} alt="Error" className={styles["internal-msg-img"]} loading="lazy" />
        <div className={styles["internal-container-info"]}>
          <span className={styles["internal-container-info-title"]}>
            {t<string>("jobOffers:actions.errorInternalVacantTitle")}
          </span>
          <span className={styles["internal-container-info-text"]}>
            {t<string>("jobOffers:actions.errorInternalVacant")}
          </span>
        </div>
      </div>
    );
  }, [candidateContext, jobContext, withProfileFull, withoutProfileFull, t]);

  const displayButtonCase = useMemo(() => {
    if (isWidgetIframe) return withIsWidgetIframe;
    if (!authContext.isAuthenticated) return withoutAuth;
    if (jobContext.selectedJob?.isInternal) return forInternalWithAuth;
    if (!candidateContext.availableToApply) return withoutProfileFull;
    return withProfileFull;
  }, [
    authContext.isAuthenticated,
    candidateContext.availableToApply,
    jobContext.selectedJob?.isInternal,
    forInternalWithAuth,
    isWidgetIframe,
    withIsWidgetIframe,
    withProfileFull,
    withoutAuth,
    withoutProfileFull
  ]);

  const callbackProfileComplete = useCallback(
    (subject: ProfileSubject) => {
      if (!subject.nextAction || !jobContext.selectedJob || subject.nextAction !== EQueryAction.apply) return;
      setJobDetailAction(jobContext.selectedJob.id);
    },
    [jobContext.selectedJob, setJobDetailAction]
  );

  const profileObserver = useMemo(() => {
    return new ProfileConcreteObserver(callbackProfileComplete);
  }, [callbackProfileComplete]);

  useEffect(() => {
    profileSubject.attach(profileObserver);
    return () => {
      profileSubject.detach(profileObserver);
    };
  }, [profileObserver]);

  return displayButtonCase;
};
