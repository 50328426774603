import { useEffect, useState } from "react";

export const useIsScrolledById = (id: string): boolean => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const content = document.getElementById(id);
    content?.addEventListener("scroll", () => {
      setScrolled((content.scrollTop as number) > 0);
    });

    return () => {
      content?.removeEventListener("scroll", () => null);
    };
  }, [id]);

  return scrolled;
};
