export const isNumberString = (value: string) => {
  if (typeof value !== "string") return false;
  const finder = new RegExp("\\D", "gm");
  return finder.test(value.replace(/\s+/g, ""));
};

export const isNumeric = (str: string) => {
  if (typeof str !== "string") return false; // we only process strings!
  return !isNaN(parseFloat(str)); // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)... // ...and ensure strings of whitespace fail
};

export const isValidNumberString = (value: string) => {
  return !isNaN(Number(value));
};
