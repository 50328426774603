import {
  blog,
  facebook,
  forCompanies,
  frequentQuestions,
  fullBasePath,
  instagram,
  jobsByCompany,
  linkedin,
  magnetoHome,
  tiktok,
  youtube
} from "@constants";

export type THeaderSearchHitItem = {
  tag: string;
  url: string;
  baseUrl: string;
  isExternal: boolean;
};

export type TSocialMediaSearchHit = {
  tag: string;
  url: string;
  icon?: string;
};

export const HEADER_MENU_PERSON: THeaderSearchHitItem[] = [
  {
    tag: "menuPerson.first",
    url: magnetoHome,
    baseUrl: "",
    isExternal: true
  },
  {
    tag: "menuPerson.second",
    url: "routes:jobs",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "menuPerson.third",
    url: "routes:byCity",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "menuPerson.fourth",
    url: jobsByCompany,
    baseUrl: "",
    isExternal: true
  },
  {
    tag: "menuPerson.fifth",
    url: "routes:bySector",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "menuPerson.sixth",
    url: "routes:byOccupation",
    baseUrl: "",
    isExternal: false
  },
  {
    tag: "menuPerson.seventh",
    url: "routes:searchHit",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "menuPerson.eighth",
    url: blog,
    baseUrl: "",
    isExternal: true
  },
  // {
  //   tag: "menuPerson.ninth",
  //   url: knowUs,
  //   baseUrl: "",
  //   isExternal: true
  // },
  {
    tag: "menuPerson.tenth",
    url: frequentQuestions,
    baseUrl: "",
    isExternal: true
  }
];

export const HEADER_MENU_COMPANY: THeaderSearchHitItem[] = [
  // {
  //   tag: "menuCompany.first",
  //   url: "routes:landing",
  //   baseUrl: `${fullBasePath}`
  // },
  // {
  //   tag: "menuCompany.second",
  //   url: "routes:jobsByCompany",
  //   baseUrl: `${fullBasePath}`
  // },
  {
    tag: "menuCompany.third",
    url: forCompanies,
    baseUrl: "",
    isExternal: true
  }
  // {
  //   tag: "menuCompany.fourth",
  //   url: "routes:jobsByCompany",
  //   baseUrl: `${fullBasePath}`
  // }
];

export const SOCIAL_MEDIAL_MENU: TSocialMediaSearchHit[] = [
  {
    tag: "instagram",
    icon: "lab la-instagram",
    url: instagram
  },
  {
    tag: "facebook",
    // icon: "lab la-facebook-f",
    url: facebook
  },
  {
    tag: "linkedin",
    icon: "lab la-linkedin",
    url: linkedin
  },
  {
    tag: "youtube",
    icon: "lab la-youtube",
    url: youtube
  },

  {
    tag: "tiktok",
    url: tiktok
  }
];
