import React, { useContext } from "react";
import { TFunction } from "next-i18next";
import { withTranslation } from "react-i18next";
import { MagnetoUIProvider, LogoutJobsTemplate, LoginJobsTemplate } from "magneto365.ui";
import { EDeviceTypes } from "@enums/filter.core.enum";
import { AuthContext } from "@contextauth.context";
import { useLandingHeader } from "@hooks/magnetoUI";
import { TRequestSearch } from "#typesfilter.core";
import withFiltersHoc from "@componentsfilters/withFiltersHoc";
import AsideMenuLanding from "@components/layout/asideMenu/asideMenuLanding.component";

type TProps = {
  t: TFunction;
  device: EDeviceTypes;
};

type ComposedProps = TProps & {
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const LandingHeaderUIType: React.FC<ComposedProps> = ({ device, t, setFilters, appliedFilters }) => {
  const { isAuthenticated } = useContext(AuthContext);

  const { logoutLandingProps, loginLandingProps } = useLandingHeader({ t, setFilters, appliedFilters });

  return (
    <MagnetoUIProvider device={device}>
      {isAuthenticated ? (
        <>
          <AsideMenuLanding
            homeUrl={loginLandingProps.homeUrl}
            listMenuProps={loginLandingProps.listMenuProps}
            jobsHeaderProps={loginLandingProps.LoginJobsHeaderProps}
            listIcon={loginLandingProps.ListIcon}
            t={t}
          />
          <LoginJobsTemplate {...loginLandingProps} />
        </>
      ) : (
        <>
          <AsideMenuLanding
            homeUrl={logoutLandingProps.homeUrl}
            listMenuProps={logoutLandingProps.listMenuProps}
            jobsHeaderProps={logoutLandingProps.logoutJobsHeaderProps}
            listIcon={logoutLandingProps.ListIcon}
            t={t}
          />
          <LogoutJobsTemplate {...logoutLandingProps} />
        </>
      )}
      <div id="magneto-signup-v2-modal" className="magneto-signup-v2-modal" data-button-create="no" />
    </MagnetoUIProvider>
  );
};

export default withTranslation(["header", "routes"])(withFiltersHoc<TProps>(LandingHeaderUIType));
