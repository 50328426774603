import { fullBasePath } from "@constants/environment.constant";

export type TPickSearchHit = {
  tag: string;
  routePath: string;
  baseUrl: string;
};
export const PICK_SEARCH_HIT: TPickSearchHit[] = [
  {
    tag: "searchHit:pick.byCompany",
    routePath: "routes:byCompany",
    baseUrl: fullBasePath
  },
  {
    tag: "searchHit:pick.byIndustry",
    routePath: "routes:byIndustry",
    baseUrl: fullBasePath
  },
  {
    tag: "searchHit:pick.byCity",
    routePath: "routes:byCity",
    baseUrl: fullBasePath
  },
  {
    tag: "searchHit:pick.searchHit",
    routePath: "routes:searchHit",
    baseUrl: fullBasePath
  }
];
