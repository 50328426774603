import React, { useContext } from "react";
import Image from "next/image";
import { ReferredContext } from "@context";
import { TReferralSites } from "#types/referred";
import style from "./referredHeaderType.module.scss";
import Description from "@components/landing/cardDescription/description.wrap";

const HeaderReferred = () => {
  const { referralSites } = useContext(ReferredContext);

  const { title, description, logoImage, backgroundImage } = referralSites as TReferralSites;

  return (
    <header className={style.header}>
      <div className={style.image}>
        <Image alt="logo" src={logoImage} height={43} width={300} objectFit="contain" loading="lazy" />
      </div>
      <div className={style.slogan}>
        <Image className={style["slogan-image"]} src={backgroundImage} alt="logo" layout="fill" priority={true} />
        <div className={style["slogan-content"]}>
          <h2>{title}</h2>
          {/* TODO: review is this  dangerouslySetInnerHTML is necessary wrap in dynamic*/}
          <Description description={description} />
        </div>
      </div>
    </header>
  );
};

export default HeaderReferred;
