import { TFilter, TRange } from "#types/filter.core";

export const totalAppliedFiltersReducer = ({ filters }: { filters: TFilter[] }) => {
  return (total: number, [field, value]: [string, Array<string | number | boolean | TRange<string | number>>]) => {
    const filterSetting = filters.find((filter) => filter.field === field);

    if (filterSetting?.renderType?.startsWith("CUSTOM") && filterSetting?.values?.length === 1) return total;
    return total + value.length;
  };
};
