export class ApplicationsSingleton {
  private static instance: ApplicationsSingleton;
  private readonly applications: Map<number, "DISPATCHING" | "COMPLETED">;
  private constructor() {
    this.applications = new Map();
  }

  public static getInstance(): ApplicationsSingleton {
    if (!ApplicationsSingleton.instance) {
      ApplicationsSingleton.instance = new ApplicationsSingleton();
    }
    return ApplicationsSingleton.instance;
  }

  public addApplication(vacantId: number | null) {
    if (!vacantId) return;
    this.applications.set(vacantId, "DISPATCHING");
  }

  public completeApplication(vacantId: number | null) {
    if (!vacantId) return;
    this.applications.set(vacantId, "COMPLETED");
  }

  public hasApplication(vacantId: number | null) {
    if (!vacantId) return undefined;
    return this.applications.get(vacantId);
  }
}
