import React from "react";
import { useIsWidgetToApply } from "@hooks";
import { EDeviceTypes, EWidgetType } from "@enums";
import { TMainContent } from "#types/jobs";
import JobSiteHeader from "@components/layout/headerByType/headers/jobSiteHeaderType.component";
import SearchHitHeader from "@components/layout/headerByType/headers/searchHitHeaderType.component";
import JobOffersHeader from "@components/layout/headerByType/headers/jobOffersHeaderType.component";
import ReferredHeader from "@components/layout/headerByType/headers/referredHeaderType.component";
import MagnetoUIHeader from "./headers/MagnetoUI.component";
import LandingHeaderUIType from "./headers/landingHeaderUIType.component";

type Props = {
  type: TMainContent;
  device: EDeviceTypes;
};
type TComposedType = `${TMainContent}-${boolean}`;

const HeaderByType: React.FC<Props> = ({ type, device }) => {
  const isWidget = useIsWidgetToApply(EWidgetType.iframe);
  const composedType: TComposedType = `${type}-${isWidget}`;

  switch (composedType) {
    case "jobSite-false":
      return <MagnetoUIHeader device={device} />;
    case "jobSite-true":
      // No add Menu HTML Without JS
      return <JobSiteHeader />;

    case "detail-true":
    case "detail-false":
      return <MagnetoUIHeader device={device} />;

    case "landing-true":
    case "landing-false":
      return <LandingHeaderUIType device={device} />;

    case "landingDetail-true":
    case "landingDetail-false":
      return <LandingHeaderUIType device={device} />;

    case "general-true":
    case "general-false":
      return <JobOffersHeader />;

    case "referred-true":
    case "referred-false":
      return <ReferredHeader />;

    case "searchHit-false":
      return <MagnetoUIHeader device={device} />;
    case "searchHit-true":
      return <SearchHitHeader />; // <MagnetoUIHeader device={device} />;

    default:
      return <JobOffersHeader />;
  }
};

export default HeaderByType;
