import "videojs-youtube";
import videojs, { VideoJsPlayerOptions, VideoJsPlayerPluginOptions } from "video.js";
import React, { useEffect, useRef } from "react";

import { isYoutubeUrl } from "@utils/validations/isYoutubeUrl.validation";

const videoPlayerStyle = { width: "100%" };

type BaseProps = {
  vjsOptions: VideoJsPlayerOptions | VideoJsPlayerPluginOptions;
  className?: string;
};

const CustomVideo: React.FC<BaseProps> = ({ vjsOptions, className }) => {
  const videoNode = useRef<HTMLVideoElement>(null);
  const playerRef = useRef<videojs.Player>();

  useEffect(() => {
    if (!playerRef.current) {
      const videoElement = videoNode.current;

      if (!videoElement) return;

      const videoSrc = vjsOptions.src;
      const isYouTubeVideo = isYoutubeUrl(`${videoSrc}`);
      const videoJsOptions = isYouTubeVideo
        ? {
            ...vjsOptions,
            // eslint-disable-next-line camelcase
            youtube: { iv_load_policy: 1, showinfo: 0 },
            techOrder: ["youtube"],
            sources: [{ type: "video/youtube", src: videoSrc }]
          }
        : vjsOptions;
      playerRef.current = videojs(videoElement, videoJsOptions);

      if (!isYouTubeVideo) {
        playerRef.current.src(`${videoSrc}`);
      }
    }
  }, [vjsOptions]);

  return (
    <div data-vjs-player="true">
      <video ref={videoNode} style={videoPlayerStyle} data-setup="{}" className={`${className} video-js`} />
    </div>
  );
};

export default CustomVideo;
