export const removeUserBackBtn = () => {
  let tried = 0;

  const interval = setInterval(() => {
    tried++;
    const userBackBtn = document.getElementById("userback_button_container");

    if (userBackBtn) {
      userBackBtn.remove();
      clearInterval(interval);
    }

    if (tried >= 100) {
      clearInterval(interval);
    }
  }, 2000);
};
