import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useRouter } from "next/router";
import { TFunction, Trans } from "next-i18next";
import { IFilter, IPaginationProps, ISideFilter, ISortBar } from "magneto365.ui";
import { useFilter, useValueByDevice } from "@hooks";
import { ESortTypes } from "@enums/filter.core.enum";
import { formatNumber } from "@utils/common";
import { getQueyParams } from "@utils/url";
import { getLocaleCountry } from "@utils/jobOffers";
import { capitalize } from "@utils/strings";
import { getCurrentDayMonthAndYear } from "@utilsdates";
import { LandingContext, SearchHitContext } from "@contextsearchHit.context";

type Props = {
  t: TFunction;
};

type TUseMagnetoFilterReturn = {
  sortBarProps: Omit<ISortBar, "isFiltersOpen" | "setIsFiltersOpen">;
  sideFilterProps: Omit<ISideFilter, "isFiltersOpen" | "setIsFiltersOpen">;
  paginationProps: IPaginationProps;
  customParagraph: string;
  setJobSelected: (id: number | null) => void;
};

export const useMagnetoFilter = ({ t }: Props): TUseMagnetoFilterReturn => {
  const [textOrderFilter, setTextOrderFilter] = useState<string>(t<string>("jobOffers:actions.orderBy"));
  const { category } = useContext(SearchHitContext);
  const { landingPage } = useContext(LandingContext);

  const {
    totalAppliedFilters,
    loading,
    totalRows,
    appliedFilters,
    filterSettings,
    device,
    searchResponse,
    h1,
    rows,
    magnetoUIActiveFilters,
    setFilters,
    setIsApplied,
    clearFilters,
    unApplyWithChild,
    getOptionsOnLoad,
    getOptionsOnSearch,
    setJobSelected,
    totalHits
  } = useFilter();
  const { query } = useRouter();
  const numberOfButtons: number = useValueByDevice(3, 5);

  const { order } = appliedFilters ?? {};
  // const scrolled = useIsScrolledById("jobs-content--left");
  const querySearch = getQueyParams("q", query);
  const hasData = useMemo(() => !!searchResponse?.rows?.length, [searchResponse?.rows?.length]);
  const orderFields = useMemo(() => filterSettings?.order?.[device]?.fields || [], [filterSettings?.order, device]);

  useEffect(() => {
    const html = document.getElementsByTagName("html");
    if (!html?.length) return;
    html[0]?.scrollTo({ top: 0 });
  }, [rows]);

  useEffect(() => {
    if (!appliedFilters?.order || !orderFields.length) return;
    const field = orderFields.find((item) => item.field == appliedFilters.order.field);
    field && setTextOrderFilter(field.label);
  }, [appliedFilters?.order, orderFields]);

  const mainTitle: string = useMemo(() => {
    if (!totalRows) return "";
    if (querySearch) return t<string>("jobOffers:jobsTableTitleSearch", { search: capitalize(querySearch.toString()) });
    else if (h1) return h1;
    else if (hasData)
      return t<string>("jobOffers:jobsTableTitle", {
        pagePlace: landingPage?.companyName ? landingPage.companyName : getLocaleCountry()
      });
    return "";
  }, [totalRows, querySearch, t, h1, hasData, landingPage?.companyName]);

  const filterSummary: string = useMemo(() => {
    if (!totalHits) return "";
    const count = totalHits === 1 ? totalHits : (formatNumber(totalHits) as unknown as number);
    return t<string>(`filters:filtersSideBar.summary.results`, { count });
  }, [t, totalHits]);

  const handleMenu = useCallback(
    (menuItem: { key: string }) => {
      if (loading) return;
      setFilters({
        ...appliedFilters,
        order: { ...order, field: menuItem?.key },
        paginator: { ...appliedFilters.paginator, page: 1 }
      });
    },
    [appliedFilters, loading, order, setFilters]
  );

  const selectedJobFilter = useCallback(
    (id: number | null) => {
      if (id === null) setJobSelected(null);
      const jobSelected = searchResponse?.rows.find((job) => job.id == id);
      if (jobSelected) setJobSelected(jobSelected);
    },
    [searchResponse.rows, setJobSelected]
  );

  const orderFilter = useCallback(() => {
    const isOrderAsc = order?.order === ESortTypes.ASC;
    if (!loading) {
      setFilters({
        ...appliedFilters,
        order: {
          ...order,
          order: isOrderAsc ? ESortTypes.DSC : ESortTypes.ASC
        },
        paginator: { ...appliedFilters.paginator, page: 1 }
      });
    }
  }, [appliedFilters, loading, order, setFilters]);

  const onChangePagination = useCallback(
    (page: number, pageSize: number) => {
      if (pageSize !== appliedFilters?.paginator?.pageSize) return;

      setFilters({
        ...appliedFilters,
        paginator: { page, pageSize }
      });
    },
    [appliedFilters, setFilters]
  );

  const sortBarProps = useMemo(() => {
    const titleOrder = order.order === ESortTypes.ASC ? "ascendingOrder" : "descendingOrder";
    return {
      textSortButton: t<string>("filters:filtersSideBar.btn"),
      mainTitle,
      filterSummary,
      textOrderFilter,
      orderFields,
      orderByText: t<string>("jobOffers:actions.orderBy"),
      loading,
      titleBtnOrder: t<string>(`jobOffers:actions.${titleOrder}`),
      orderFilter,
      setFilter: handleMenu,
      infoMessageQuotas: t<string>(`jobOffers:actions.infoMessageQuotas`),
      widthInfoMessage: "13rem"
    };
  }, [order.order, t, mainTitle, filterSummary, textOrderFilter, orderFields, loading, orderFilter, handleMenu]);

  const sideFilterProps = useMemo(() => {
    return {
      title: (
        <Trans t={t} components={{ bold: <strong /> }} i18nKey={"filters:filtersSideBar.title"} />
      ) as unknown as string,
      filters: magnetoUIActiveFilters as unknown as IFilter[],
      totalAppliedFilters,
      filterSummary,
      buttonText: t<string>("filters:filtersSideBar.clearFilters"),
      loading,
      textBtnMain: t<string>("filters:filtersSideBar.textBtnMain"),
      titleBtnClose: t<string>("filters:filtersSideBar.titleBtnClose"),
      setIsApplied,
      clearFilters,
      unApplyWithChild,
      getOptionsOnLoad,
      getOptionsOnSearch
    };
  }, [
    totalAppliedFilters,
    filterSummary,
    loading,
    magnetoUIActiveFilters,
    setIsApplied,
    clearFilters,
    unApplyWithChild,
    getOptionsOnLoad,
    getOptionsOnSearch,
    t
  ]);

  const paginationProps = useMemo(() => {
    return {
      total: totalRows,
      current: appliedFilters?.paginator?.page,
      pageSize: appliedFilters?.paginator?.pageSize,
      loading,
      onChange: onChangePagination,
      numberOfButtons,
      prevTitle: t<string>("pagination.prevPage"),
      nextTitle: t<string>("pagination.nextPage")
    };
  }, [appliedFilters?.paginator, totalRows, loading, numberOfButtons, onChangePagination, t]);

  const customParagraph = useMemo(() => {
    if (!category?.paragraph) return "";
    const { day, month, year } = getCurrentDayMonthAndYear();
    return category.paragraph
      .replace("{{day}}", day?.toString())
      .replace("{{month}}", month)
      .replace("{{year}}", year?.toString())
      .replace("{{title}}", category.h1 ? category.h1 : "");
  }, [category]);

  return {
    sortBarProps,
    sideFilterProps,
    paginationProps,
    customParagraph,
    setJobSelected: selectedJobFilter
  };
};
