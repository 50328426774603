import React, { useContext } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { TFunction, withTranslation } from "next-i18next";
import { Layout } from "antd";
import { useIsScrolledById } from "@hooks";
import MagnetoHeaderIcon from "@public/images/magneto-web.svg";
import HeaderButtons from "../children/buttons.wrap";
import Link from "next/link";
import styles from "./jobOffersHeaderType.module.scss";
import { AuthContext } from "@context";
import { fillUrlUtms, getHomeUrl } from "@utils/url";
import MenuComponent from "../children/menuProfile/menu.component";
import classNames from "classnames";

const { Header } = Layout;
const headerClass = "job-offers-header";
type Props = { t: TFunction };

const JobOffersHeader: React.FC<Props> = ({ t }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const scrolled = useIsScrolledById("jobs-content--left");

  const { query } = useRouter();

  return (
    <Header className={classNames(styles[headerClass], { [styles["hide-header"]]: scrolled })}>
      <div className={styles[`${headerClass}__content-container`]}>
        <div className={styles["img-container"]}>
          <Link href={fillUrlUtms(getHomeUrl(isAuthenticated, t), query)} passHref>
            <a>
              <Image
                alt="logo"
                src={MagnetoHeaderIcon}
                className={styles[`${headerClass}__header-icon`]}
                width={130}
                height={32}
                loading="lazy"
              />
            </a>
          </Link>
        </div>
        {isAuthenticated && <MenuComponent />}
      </div>
      <HeaderButtons />
    </Header>
  );
};

export default withTranslation("routes")(JobOffersHeader);
