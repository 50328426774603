import { isNull } from "lodash";
import { TLanding } from "#types/landing";
import { EJobsitesVisibility } from "@enums/landing.enum";
import { isValidNumberString } from "@utils/validations";

export const fMagnetoAdapter = (fMagneto: string | string[] | undefined): number | undefined => {
  if (!fMagneto || Array.isArray(fMagneto)) return;
  let value;
  if (isValidNumberString(fMagneto)) {
    const numberValue = Number(fMagneto);
    value = [0, 1].includes(numberValue) ? numberValue : undefined;
  }
  return value;
};

export const isPublicJobsiteAdapter = (landing: TLanding | null): EJobsitesVisibility => {
  if (isNull(landing) || landing.companyId === 1) return EJobsitesVisibility.publicJobsite;
  return EJobsitesVisibility.privateJobsite;
};
