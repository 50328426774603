import { reduce, values } from "ramda";
import { isNonEmptyArray, isNotEmpty } from "ramda-adjunct";

import { isNonEmptyObject } from "../common";
import { TQuestionnaires, TKeyAnswer, TKeyAnswerQuestion } from "#types/detail";
import { EQuestionType } from "@enums/questionnaire.enum";
import { MAX_LENGHT } from "@constants";

const totalQuestionsReducer = (acc: number, cur: TQuestionnaires) => {
  return isNonEmptyArray(cur?.questions) ? acc + cur.questions.length : acc;
};

const totalAnswersReducer = (acc: number, cur: TKeyAnswerQuestion) => {
  const questionsWithAnswers = values(cur).filter((cc) => {
    if ([EQuestionType.unique, EQuestionType.multiple].includes(cc.type)) {
      return isNonEmptyArray(cc.value);
    } else {
      const limit = cc.type === EQuestionType.openShort ? MAX_LENGHT.shortQuestionArea : MAX_LENGHT.longQuestionArea;
      return isNotEmpty(cc.value) && cc.value.length <= limit;
    }
  });
  return acc + questionsWithAnswers.length;
};

export const hasAnsweredAllQuestions = (questionnaires: TQuestionnaires[], answers: TKeyAnswer) => {
  if (isNonEmptyArray(questionnaires) && isNonEmptyObject(answers)) {
    const totalQuestions = reduce(totalQuestionsReducer, 0, questionnaires);
    const totalAnswers = reduce(totalAnswersReducer, 0, values(answers));

    return totalQuestions === totalAnswers;
  }

  return false;
};
