import React, { FC, Fragment, useState } from "react";
import { TFunction, withTranslation } from "next-i18next";
import { Collapse } from "antd";
import GeneralLinks from "../generalLinks/component";
import { globalOptions } from "@schemas/footer/globalOptions.schema";
import style from "./mobileLinks.module.scss";
import { EFooterMenuType } from "@enumsfooterSections.enum";
import { TPagesLinks } from "@schemasfooter";

const { Panel } = Collapse;

type TProps = {
  t: TFunction;
};

type TState = {
  [key: string]: boolean;
};

const MobileLinks: FC<TProps> = ({ t }) => {
  const [arrow, setArrow] = useState<TState>({});

  const handleChange = (key: string | string[]) => {
    if (typeof key === "object") return;
    setArrow({
      [key]: true
    });
  };

  return (
    <Collapse
      className={style.collapse}
      onChange={handleChange}
      expandIconPosition="end"
      destroyInactivePanel
      accordion
    >
      {globalOptions &&
        globalOptions.map(({ title, links, type, subMenus }, index) => (
          <Fragment key={`mobileLink${index}`}>
            {type === EFooterMenuType.menu && (
              <Panel
                header={<h4 className={style.title}>{t<string>(title as string)}</h4>}
                key={`${index}-${title}`}
                showArrow={false}
                extra={<i className={`las la-angle-${arrow[`${index}-${title}`] ? "up" : "down"}`} />}
              >
                <GeneralLinks links={links as TPagesLinks[]} style={style} />
              </Panel>
            )}
            {type === EFooterMenuType.submenu && (
              <>
                {subMenus?.map(({ title, links }, index) => (
                  <Panel
                    header={<h4 className={style.title}>{t<string>(title as string)}</h4>}
                    key={`${index}-${title}`}
                    showArrow={false}
                    extra={<i className={`las la-angle-${arrow[`${index}-${title}`] ? "up" : "down"}`} />}
                  >
                    <GeneralLinks links={links as TPagesLinks[]} style={style} />
                  </Panel>
                ))}
              </>
            )}
          </Fragment>
        ))}
    </Collapse>
  );
};

export default withTranslation(["routes", "footer"])(MobileLinks);
