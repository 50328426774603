import { TFunction } from "next-i18next";
import formatDuration from "date-fns/formatDuration";
import * as Locales from "date-fns/locale";
import { language } from "@constants/environment.constant";

const lang = new Intl.Locale(language).language;
const YEAR_IN_MONTHS = 12;

const monthsToYears = (total: number) => {
  const years = Math.floor(total / YEAR_IN_MONTHS);
  const months = total % YEAR_IN_MONTHS;
  return { years, months };
};

export const getFormattedExperience = (monthsExperience: number | null, t: TFunction): string | null => {
  if (!monthsExperience) return null;
  const { years, months } = monthsToYears(monthsExperience);
  const locale = Locales[lang as keyof typeof Locales];

  const yearsFormat = years > 0 ? formatDuration({ years }, { locale }) : "";
  const monthsFormat = months > 0 ? formatDuration({ months }, { locale }) : "";

  let experience = "";
  if (yearsFormat) experience += yearsFormat;
  if (monthsFormat) {
    if (experience) experience += t<string>("jobOffers:formatted.connector");
    experience += monthsFormat;
  }

  return t<string>("jobOffers:formatted.experience", { experience });
};
