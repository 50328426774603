import { FC } from "react";
import { TPagesLinks } from "@schemasfooter";
import { TFunction } from "next-i18next";
import style from "../desktopLinks.module.scss";
import GeneralLinks from "../../generalLinks/component";

type TMenu = {
  t: TFunction;
  title?: string;
  links?: TPagesLinks[];
  i?: number;
};

const Menu: FC<TMenu> = ({ t, title, links, i }) => {
  return (
    <div className={style.content} key={`${title}-${i}`}>
      <h4 className={style.title}>{t<string>(title as string)}</h4>
      <GeneralLinks links={links as TPagesLinks[]} style={style} />
    </div>
  );
};

export default Menu;
