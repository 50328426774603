//import { useRouter } from "next/router";
//import { useTranslation } from "next-i18next";
import { useContext } from "react";
import { JobsContext } from "@contextjobs.context";

export const useOverdriveData = () => {
  //const { query, pathname } = useRouter();
  //const { t } = useTranslation("routes");

  const { overdriveLibrary } = useContext(JobsContext);

  return { overdriveLibrary };
};
