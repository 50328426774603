import React, { FC, Fragment } from "react";
import { TFunction, withTranslation } from "next-i18next";
import { globalOptions } from "@schemas/footer/globalOptions.schema";
import { EFooterMenuType } from "@enumsfooterSections.enum";
import Menu from "./children/menu.component";
import Submenu from "./children/subMenu.component copy";
import { TGlobalProps } from "#types/footer";

type TDesktopLinks = {
  t: TFunction;
};

const DesktopLinks: FC<TDesktopLinks> = ({ t }) => {
  return (
    <Fragment>
      {globalOptions.map(({ title, links, type, subMenus }, i) => (
        <Fragment key={`link${i}`}>
          {type === EFooterMenuType.menu && <Menu t={t} title={title} links={links} i={i} key={`${title}-${i}`} />}
          {type === EFooterMenuType.submenu && (
            <Submenu t={t} subMenus={subMenus as TGlobalProps[]} key={`${type}-${i}`} />
          )}
        </Fragment>
      ))}
    </Fragment>
  );
};

export default withTranslation(["routes", "footer"])(DesktopLinks);
