import React, { Fragment } from "react";
import { LogoutTemplate } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import { useMagnetoHeader } from "@hooks/magnetoUI";
import { TRequestSearch } from "#types/filter.core";
import withFiltersHoc from "@components/filters/withFiltersHoc";
import AsideMenu from "@components/layout/asideMenu/asideMenu.component";
import { SOCIAL_MEDIAL_MENU } from "@schemas/searchHit";

type TProps = {
  t: TFunction;
};

type ComposedProps = TProps & {
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const MagnetoLogoutHeader: React.FC<ComposedProps> = ({ t, setFilters, appliedFilters }) => {
  const { logoutPropsHeader } = useMagnetoHeader({ t, setFilters, appliedFilters });
  return (
    <Fragment>
      <AsideMenu {...logoutPropsHeader.headerDrawerMenuProps} t={t} listIcon={SOCIAL_MEDIAL_MENU} />

      <LogoutTemplate {...logoutPropsHeader} />
    </Fragment>
  );
};

export default withTranslation(["header", "routes"])(withFiltersHoc<TProps>(MagnetoLogoutHeader));
