import { TCounterResponse, TFilter, TRequestFilter, TFilterEqualValue, TRange } from "#types/filter.core";
import { TFunction } from "next-i18next";

type Props = {
  filters: TFilter[];
  counters: TCounterResponse[];
  applied: TRequestFilter;
  t: TFunction;
};

export type TMagnetoUIActiveFiltersValue = TFilterEqualValue & {
  isApplied: boolean;
  total?: number;
};

export type TMagnetoUIActiveFilter = TFilter & {
  searchPlaceholder?: string;
  filtersApplied?: Array<string | number | boolean | TRange<string | number>>;
};

type TNormalizeToChildProps = {
  child: TMagnetoUIActiveFilter;
  applied: TRequestFilter;
  t: TFunction;
};

const normalizeToChild = ({ child, applied, t }: TNormalizeToChildProps): TMagnetoUIActiveFilter => {
  const newChild = structuredClone(child);
  const { field } = newChild;

  const searchPlaceholder = `${t("filters:filtersSideBar.search")} ${child.label?.toLowerCase()}`;
  newChild.filtersApplied = structuredClone(applied[field]) || [];

  if (newChild.child) newChild.child = normalizeToChild({ child: newChild.child, applied, t });
  return { ...newChild, searchPlaceholder };
};

export const normalizeFilter = ({ filters, counters, applied, t }: Props): TMagnetoUIActiveFilter[] => {
  const magnetoUIActiveFilters: TMagnetoUIActiveFilter[] = [];

  filters.forEach((filter) => {
    const newFilter: TMagnetoUIActiveFilter = structuredClone(filter);
    const { field } = newFilter;

    const auxValues: TMagnetoUIActiveFiltersValue[] = [];

    newFilter.values?.forEach((value: TFilterEqualValue) => {
      const auxCounter = counters
        .find((counter) => counter.field === field)
        ?.counts?.find((count) => count.id === value.id);

      // if(value.label && value.id && auxCounter?.total){
      if (value.label && value.id) {
        const isApplied = applied[field]?.some((applyValue) => {
          if (typeof applyValue === "object") return applyValue?.id === value.id;
          return applyValue === value.id;
        });
        auxValues.push({ ...value, total: auxCounter?.total || 0, isApplied });
      }
    });

    const searchPlaceholder = `${t("filters:filtersSideBar.search")} ${newFilter.label?.toLowerCase()}`;

    if (newFilter.child) {
      newFilter.child = normalizeToChild({ child: newFilter.child as TFilter, applied, t });
      newFilter.filtersApplied = structuredClone(applied[field]) || [];
    }

    magnetoUIActiveFilters.push({ ...newFilter, searchPlaceholder, values: auxValues });
  });

  return magnetoUIActiveFilters;
};
