import { isNonEmptyArray } from "ramda-adjunct";
import { TFunction } from "next-i18next";
import { TEducationLevel } from "#typesjobs";

type ComposedProps = { t: TFunction };

export const getFormattedEducationLevels = ({ t }: ComposedProps, educationLevels: TEducationLevel[] | null) => {
  let formattedEducationLevels = "";

  if (isNonEmptyArray(educationLevels)) {
    const { name: firstLevel } = educationLevels[0];

    if (educationLevels.length > 1) {
      const { name: lastLevel } = educationLevels[educationLevels.length - 1];
      formattedEducationLevels = `${firstLevel} ${t<string>("jobOffers:vacantDetails.to")} ${lastLevel}`;
    } else formattedEducationLevels = `${firstLevel}`;

    return formattedEducationLevels;
  }

  return formattedEducationLevels;
};
