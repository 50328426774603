import React, { useContext, useMemo } from "react";
import { useRouter } from "next/router";
import { useTranslation } from "next-i18next";
import { message } from "antd";
import { AuthContext, JobsContext, LandingContext } from "@context/jobs.context";
import { JobsRepository, JobsWithAuthRepository, TagManagerRepository } from "@repositories";
import { getAllUtms, getQueyParams, stateToQueryParams } from "@utils/url";
import { EQueryAction, EEventsGA4, EVacantViews, EViewItemListName, EDeviceTypes } from "@enums";
import { TCounterResponse, TFilter, TGlobalFilterSettings, TRequestFilter, TRequestSearch } from "#types/filter.core";
import { TJobQuestions, TSendAnswers, TSourceOrigins } from "#types/detail";
import { TJobRecord, TOffersSuggested, TSimilarVacancies } from "#types/jobs";
import { fMagnetoAdapter, isPublicJobsiteAdapter } from "@utils/adapters/sourceOrigins.adapter";
import { ApplicationsSingleton } from "@classes/applicationsSingleton.class";
import { isNonEmptyArray } from "ramda-adjunct";
import { jsonDataLayer, viewItemListSectionFromRoute } from "@utils/googleTagManager";

const applicationsSingleton = ApplicationsSingleton.getInstance();

type TInjectedProps = {
  loading: boolean;
  filters: TFilter[];
  counters: TCounterResponse[];
  totalRows: number;
  totalAppliedFilters: number;
  appliedFilters: TRequestSearch;
  filterSettings: TGlobalFilterSettings;
  clearFilters: () => Promise<void>;
  setLoading: (payload: boolean) => void;
  applyJobWithAnswer: (answers: TSendAnswers, tagManager?: any) => void;
  changeOfferState: (jobId: number | null, view: string, isSaved: boolean | null) => void;
  setFilters: (payload: TRequestSearch) => Promise<void>;
  setJobSelected: (record: TJobRecord | null) => void;
  setJobDetailAction: (jobId: number | null) => void;
};

const withFiltersHoc = <T,>(WrappedComponent: React.FC<T & TInjectedProps>) => {
  const WithFilters: React.FC<T> = (props: T) => {
    const contextValue = useContext(JobsContext);
    const authContext = useContext(AuthContext);
    const nextRouter = useRouter();
    const landingContext = useContext(LandingContext);
    const { landingPage } = landingContext;
    const { setContextValue } = contextValue;
    const { t } = useTranslation();
    const { query } = nextRouter;
    const querySearch = getQueyParams("q", query);

    const repository = useMemo<typeof JobsRepository>(() => {
      if (authContext.isAuthenticated) return JobsWithAuthRepository;
      return JobsRepository;
    }, [authContext.isAuthenticated]);

    const sourceOrigins = useMemo<TSourceOrigins>(() => {
      const fMagnetoParam = fMagnetoAdapter(nextRouter.query?.["f_magneto"]);
      const isPublicJobsite = isPublicJobsiteAdapter(landingContext.landingPage);
      return {
        // eslint-disable-next-line camelcase
        f_magneto: fMagnetoParam,
        isPublicJobsite
      };
    }, [nextRouter.query, landingContext]);

    const isJobSlugPage = useMemo<boolean>(() => {
      if (nextRouter.query?.jobSlug) return true;
      return false;
    }, [nextRouter.query?.jobSlug]);

    const totalAppliedFilters: number = useMemo(() => {
      const filters = contextValue.filtersApplied.filters;
      let total = 0;
      for (const filed in filters) {
        const filterSetting = contextValue.filterSettings.filters.find((filter) => filter.field === filed);
        if (!filterSetting) continue;
        const { renderType, values } = filterSetting;
        if (renderType.startsWith("CUSTOM") && values.length === 1) continue;
        total = total + filters[filed].length;
      }
      return total;
    }, [contextValue.filtersApplied.filters, contextValue.filterSettings.filters]);

    const setJobSelected = (record: TJobRecord | null) => {
      setContextValue((current) => ({
        ...current,
        selectedJob: record,
        offerSuggested: [],
        jobQuestions: null,
        jobDetailAction: null
      }));
      record &&
        record.jobSlug &&
        setOfferSuggested(record.jobSlug).then((offersSuggested) => {
          isNonEmptyArray(offersSuggested) &&
            TagManagerRepository.viewItemList(
              jsonDataLayer(
                offersSuggested,
                EViewItemListName.vacante.replace("{company_name}", record.companyName as string),
                viewItemListSectionFromRoute(nextRouter, true),
                EEventsGA4.viewItemList
              )
            );
        });
      record && record.id && setSimilarVacancies(record.id, landingPage?.companyId);
    };

    const setFilters = async (payload: TRequestSearch) => {
      setJobSelected(null);
      setContextValue((current) => ({ ...current, filtersApplied: payload, loading: true }));
      const searchResponse = await repository.search(payload);
      const selectedJob = contextValue.device === EDeviceTypes.desktop ? searchResponse.rows[0] || null : null;
      const similarVacancies =
        (selectedJob && (await repository.getSimilarVacancies(selectedJob.id, landingPage?.companyId))) || [];

      const offersSuggestedResponse: TOffersSuggested[] =
        (selectedJob && (await repository.getOffersSuggested(selectedJob.jobSlug))) || [];
      setContextValue((current) => ({
        ...current,
        searchResponse,
        similarVacancies,
        selectedJob,
        offerSuggested: offersSuggestedResponse,
        loading: false
      }));
      stateToQueryParams({ ...payload, ...getAllUtms() }, true);
    };

    const setLoading = (payload: boolean) => {
      setContextValue((current) => ({ ...current, loading: payload }));
    };

    const setOfferSuggested = async (jobSlug: string) => {
      const offersSuggestedResponse: TOffersSuggested[] = await repository.getOffersSuggested(jobSlug);
      setContextValue((current) => ({ ...current, offerSuggested: offersSuggestedResponse }));
      return offersSuggestedResponse;
    };

    const setSimilarVacancies = async (vacantId: number | null, companyId?: number | null) => {
      const similarVacancies: TSimilarVacancies[] = await repository.getSimilarVacancies(vacantId, companyId);
      setContextValue((current) => ({ ...current, similarVacancies }));
    };

    const setJobDetailAction = async (jobId: number | null) => {
      if (!authContext.isAuthenticated && jobId) {
        return setContextValue((current) => ({ ...current, jobDetailAction: EQueryAction.apply }));
      } else if (!jobId) {
        return setContextValue((current) => ({ ...current, jobQuestions: null, jobDetailAction: null }));
      }
      const offerHasQuestionnaire = contextValue.selectedJob?.hasPrefilterQuestionnaire;

      if (offerHasQuestionnaire) {
        const jobQuestions: TJobQuestions = await JobsWithAuthRepository.getJobQuestions(jobId);
        return setContextValue((current) => ({ ...current, jobQuestions, jobDetailAction: EQueryAction.apply }));
      }

      if (applicationsSingleton.hasApplication(jobId)) return;
      applicationsSingleton.addApplication(jobId);
      return JobsWithAuthRepository.applyJob({ jobId, suggestedByMatcher: false, sourceOrigins }).then(
        (applyResponse) => {
          applicationsSingleton.completeApplication(jobId);

          TagManagerRepository.purchaseV4(
            jsonDataLayer(
              [contextValue.selectedJob as TJobRecord],
              EViewItemListName.generalOffers.replace(
                "{query}",
                querySearch ? (querySearch as string) : EViewItemListName.enColombia
              ),
              viewItemListSectionFromRoute(nextRouter, !!contextValue.selectedJob),
              EEventsGA4.purchase,
              { applicationId: applyResponse.data.applicationId }
            )
          );
          if (isJobSlugPage) {
            return repository.getSelectedJob(jobId.toString()).then((selectedJob) => {
              setContextValue((current) => ({ ...current, selectedJob }));
            });
          }
          return setFilters(contextValue.filtersApplied);
        }
      );
    };

    const applyJobWithAnswer = async (answers: TSendAnswers) => {
      JobsWithAuthRepository.applyJob({ ...answers, sourceOrigins }).then((applyResponse) => {
        if (isJobSlugPage) {
          return repository.getSelectedJob(answers.jobId.toString()).then((selectedJob) => {
            setContextValue((current) => ({ ...current, selectedJob, jobQuestions: null, jobDetailAction: null }));
          });
        }
        TagManagerRepository.purchaseV4(
          jsonDataLayer(
            [contextValue.selectedJob as TJobRecord],
            EViewItemListName.generalOffers.replace(
              "{query}",
              querySearch ? (querySearch as string) : EViewItemListName.enColombia
            ),
            viewItemListSectionFromRoute(nextRouter, !!contextValue.selectedJob),
            EEventsGA4.purchase,
            { applicationId: applyResponse.data.applicationId }
          )
        );
        return setFilters(contextValue.filtersApplied);
      });
    };

    const changeOfferState = async (jobId: number | null, view: string, isSaved: boolean | null) => {
      try {
        isSaved ? JobsWithAuthRepository.unsaveJob(jobId as number) : JobsWithAuthRepository.saveJob(jobId as number);

        if (view === EVacantViews.row || view === EVacantViews.listMode) {
          setContextValue((current) => ({ ...current, loading: true }));
          const searchResponse = await repository.search(contextValue.filtersApplied);
          setContextValue((current) => ({ ...current, searchResponse, loading: false }));

          if (view === EVacantViews.listMode) {
            const selectOffer = searchResponse.rows.find(
              (row) => row.id === contextValue.selectedJob?.id
            ) as TJobRecord;
            setJobSelected(selectOffer);
          }
        }

        if (view === EVacantViews.detailPage) {
          const selectOffer = await repository.getSelectedJob(jobId?.toString() as string);
          setJobSelected(selectOffer);
        }
      } catch (error) {
        setContextValue((current) => ({ ...current, loading: false }));
        message.error(t("jobOffers:error.message"));
      }
    };

    const clearFilters = async () => {
      if (!totalAppliedFilters) return;
      const emptyFilters: TRequestFilter = {};
      for (const filed in contextValue.filtersApplied.filters) {
        emptyFilters[filed] = [];
      }
      return setFilters({
        ...contextValue.filtersApplied,
        filters: emptyFilters,
        paginator: { ...contextValue.filtersApplied.paginator, page: 1 }
      });
    };

    const injectedProps: TInjectedProps = {
      loading: contextValue.loading,
      filters: contextValue.filterSettings.filters,
      counters: contextValue.searchResponse.counters,
      totalRows: contextValue.searchResponse.totalRows,
      filterSettings: contextValue.filterSettings,
      appliedFilters: contextValue.filtersApplied,
      applyJobWithAnswer,
      changeOfferState,
      setFilters,
      setLoading,
      clearFilters,
      setJobSelected,
      setJobDetailAction,
      totalAppliedFilters
    };

    return <WrappedComponent {...props} {...injectedProps} />;
  };
  return WithFilters;
};

export default withFiltersHoc;
