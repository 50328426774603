import { isEmpty } from "lodash";
import { TFunction } from "next-i18next";

export const getFormattedKeywords = (t: TFunction, keywords?: string[] | null) => {
  return keywords && !isEmpty(keywords)
    ? t("jobOffers:formatted.keywords", {
        keywords: keywords.join(", ")
      })
    : null;
};
