import React, { Fragment } from "react";
import { LoginTemplate } from "magneto365.ui";
import { TFunction, withTranslation } from "next-i18next";
import { useMagnetoHeader } from "@hooks/magnetoUI";
import { TRequestSearch } from "#typesfilter.core";
import withFiltersHoc from "@componentsfilters/withFiltersHoc";
import AsideMenu from "@components/layout/asideMenu/asideMenu.component";
import { SOCIAL_MEDIAL_MENU } from "@schemas/searchHit";

type TProps = {
  t: TFunction;
};

type ComposedProps = TProps & {
  appliedFilters: TRequestSearch;
  setFilters: (request: TRequestSearch) => Promise<void>;
};

const MagnetoLoginHeader: React.FC<ComposedProps> = ({ setFilters, appliedFilters, t }) => {
  const { loginPropsHeader, asideMenu } = useMagnetoHeader({ t, setFilters, appliedFilters });
  return (
    <Fragment>
      <AsideMenu {...asideMenu.headerDrawerMenuProps} t={t} listIcon={SOCIAL_MEDIAL_MENU} />
      <LoginTemplate {...loginPropsHeader} />
    </Fragment>
  );
};

export default withTranslation(["header", "routes"])(withFiltersHoc<TProps>(MagnetoLoginHeader));
