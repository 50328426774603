import { EQueryParams } from "@enums";
import { removeQueryParamByWindow } from "@utils/url";

export const applyWithProfileFullClosure = () => {
  let count = 0;
  let slug = "";
  function apply(btn: HTMLButtonElement, currentSlug: string) {
    slug = currentSlug;
    if (count > 0) return;
    count++;
    btn.click();
    removeQueryParamByWindow(EQueryParams.detailAction);
  }
  apply.slug = () => slug;
  apply.clear = () => (count = 0);
  return apply;
};
