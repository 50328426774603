import { ListIconLink, ListMenuItems } from "magneto365.ui";
import { TAsideMenuLanding } from "../../../@types/aside";
import style from "./asideMenu.module.scss";
const blockClassName = "aside-magneto-menu";
const AsideMenuLanding: React.FC<TAsideMenuLanding> = ({ listIcon, listMenuProps, jobsHeaderProps, homeUrl }: TAsideMenuLanding) => {
  const {
    brandMenuProps: { companyLogo, companySlug, companyUrl }
  } = jobsHeaderProps;
  return (
    <aside className={style[blockClassName]}>
      <div className={style[`${blockClassName}-logout-jobs-template--brands`]}>
        <a href={homeUrl}>{homeUrl}</a>
        <a href={companyUrl}>
          <img
            className={style[`${blockClassName}-logout-jobs-template--company-logo`]}
            src={companyLogo}
            alt={companySlug ? companySlug : ""}
          />
        </a>
      </div>
      <div className={style[`${blockClassName}-logout-jobs-template--jobs-menu`]}>
        <ListMenuItems {...listMenuProps} />
      </div>

      <div className={style[`${blockClassName}-logout-jobs-template--social-media`]}>
        {listIcon && <ListIconLink size={34} spacing={20} listIcon={listIcon} />}
      </div>
    </aside>
  );
};

export default AsideMenuLanding;
