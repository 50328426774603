import React, { FC, Fragment, useContext } from "react";
import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";
import { TFunction, withTranslation } from "next-i18next";
import { Space } from "antd";
import PoweredByImage from "@public/assets/images/magneto-official.svg";
import GooglePlay from "@public/assets/images/Google-play.svg";
import AppStore from "@public/assets/images/Appstore.svg";
import AppGallery from "@public/assets/images/AppGallery.svg";
import linked from "@public/assets/images/logo-servicio-de-empleo.png";
import minTrabajo from "@public/assets/images/Mintrabajo_Colombia.svg";
import { AuthContext } from "@context";
import { fillUrlUtms, getHomeUrl } from "@utils/url";
import IconsSocialMedia from "@components/layout/headerByType/children/searchHit/iconsSocialMedia/component";
import DesktopLinks from "./desktopLinks/component";
import MobileLinks from "./mobileLinks/component";
import InternalLinking from "@components/common/internalLinking/component";
import { SPEResolution, appGallery, appStore, googlePlay } from "@constants/companyLinks.constant";
import style from "./globalFooter.module.scss";
import { paramExists } from "@utilsurl/paramExists.util";
import { EOcupacolLandingSlug } from "@enumssearchHit.enum ";

type Props = {
  t: TFunction;
};
const GlobalFooter: FC<Props> = ({ t }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const nextRouter = useRouter();
  const { query } = nextRouter;

  return (
    <Fragment>
      {!paramExists(EOcupacolLandingSlug.occupationByCitySlug, query) && <InternalLinking />}
      <footer className={style.content}>
        <section className={style.container}>
          <div className={style.social}>
            <Link href={fillUrlUtms(getHomeUrl(isAuthenticated, t), query)} passHref>
              <a className={style.logo} title="Logo magneto">
                <Image alt="Magneto" src={PoweredByImage} loading="lazy" />
              </a>
            </Link>
            <h4 className={style.title}>{t<string>("footer:titles.socialMedia")}</h4>

            <IconsSocialMedia size={20} />

            <div className={style.appDownloadSection}>
              <h4 className={style.title}>{t<string>("footer:titles.downloadApp")}</h4>
              <div className={style.downloadAppLinks}>
                <Link href={googlePlay} passHref>
                  <a className={style.logoAppDownload} title="Logo Google Play">
                    <Image alt="Google Play" src={GooglePlay} loading="lazy" />
                  </a>
                </Link>
                <Link href={appStore} passHref>
                  <a className={style.logoAppDownload} title="Logo App store">
                    <Image alt="App Store" src={AppStore} loading="lazy" />
                  </a>
                </Link>
                <Link href={appGallery} passHref>
                  <a className={style.logoAppDownload} title="Logo App Gallery">
                    <Image alt="App Gallery" src={AppGallery} loading="lazy" />
                  </a>
                </Link>
              </div>
            </div>
          </div>
          <DesktopLinks />
          <MobileLinks />
        </section>
        <section className={style.container}>
          <div className={style.extra}>
            <small className={style.small}>
              {t<string>("footer:linked")}
              <a
                href={fillUrlUtms(SPEResolution, query)}
                target="_blank"
                rel="noreferrer noopener"
                title={t<string>("footer:resolution")}
              >
                {t<string>("footer:resolution")}
              </a>
            </small>
            <Space>
              <div className={style["content-img"]}>
                <Image
                  src={linked}
                  alt={t<string>("footer:linked")}
                  objectFit="contain"
                  layout="responsive"
                  loading="lazy"
                />
              </div>
              <div className={style["content-img"]}>
                <Image src={minTrabajo} alt="Logo Min-trabajo" objectFit="contain" layout="responsive" loading="lazy" />
              </div>
            </Space>
          </div>
        </section>
      </footer>
    </Fragment>
  );
};

export default withTranslation()(GlobalFooter);
