import { FC } from "react";
import { TPagesLinks } from "@schemasfooter";
import { TFunction } from "next-i18next";
import style from "../desktopLinks.module.scss";
import GeneralLinks from "../../generalLinks/component";
import { TGlobalProps } from "#types/footer";

type TMenu = {
  t: TFunction;
  subMenus: TGlobalProps[];
};

const Submenu: FC<TMenu> = ({ t, subMenus }) => {
  return (
    <div className={style.content}>
      {subMenus.map(({ title, links }, i) => (
        <div className={style.subMenus} key={`${title}-${i}`}>
          <h4 className={style.title}>{t<string>(title as string)}</h4>
          <GeneralLinks links={links as TPagesLinks[]} style={style} />
        </div>
      ))}
    </div>
  );
};

export default Submenu;
