import { EEmploymentType } from "@enumsjobPosting.enum";

/*
Previously, the id of the contractType was used, due to a compatibility problem between environments, the order was used.
*/
export const convertedEmploymentType = (contractType: number | null) => {
  switch (contractType) {
    case 1:
      return EEmploymentType.fixed_term;
    case 2:
      return EEmploymentType.full_time;
    case 3:
    case 4:
      return EEmploymentType.temporary;
    case 5:
      return EEmploymentType.contractor;
    case 6:
      return EEmploymentType.intern;
    case 26:
      return EEmploymentType.internship;
    default:
      return EEmploymentType.unknown;
  }
};

export const convertedEmploymentTypeJobSchema = (contractType: number | null) => {
  switch (contractType) {
    case 1:
      return EEmploymentType.fixed_term;
    case 2:
      return EEmploymentType.full_time;
    case 3:
    case 4:
      return EEmploymentType.temporary;
    case 5:
      return EEmploymentType.contractor;
    case 6:
    case 26:
      return EEmploymentType.intern;
    default:
      return;
  }
};
