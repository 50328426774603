import { keys } from "ramda";
import { isNonEmptyObject } from "@utils/common";
import { TKeyAnswer, TSendQuestionnaire, TSendQuestion } from "#types/detail";
import { EQuestionType } from "@enums/questionnaire.enum";

export const answersToQuestionnaires = (answers: TKeyAnswer): TSendQuestionnaire[] => {
  if (isNonEmptyObject(answers)) {
    const questionnairesMapper = (questionnaireId: number) => {
      const questionsMapper = (questionId: number): TSendQuestion => {
        const answer = answers[questionnaireId][questionId];
        if (answer.type === EQuestionType.multiple || answer.type === EQuestionType.unique) {
          return {
            id: questionId,
            answer: answer.value,
            type: answer.type
          };
        } else if (answer.type === EQuestionType.openLong || answer.type === EQuestionType.openShort) {
          return {
            id: questionId,
            openAnswer: answer.value,
            type: answer.type
          };
        } else {
          return { id: questionId, answer: [], type: answer.type };
        }
      };

      return {
        id: questionnaireId,
        questions: keys(answers[questionnaireId]).map(questionsMapper)
      };
    };

    return keys(answers).map(questionnairesMapper);
  }

  return [];
};
