import { ICityDetail } from "magneto365.ui";
import { TFunction } from "next-i18next";
import { fullBasePath } from "../../constants";
import { transformSlugUrlToTitle } from "../url/slugUrlToTitle.util";

export const transformSlugToCityOffer = (slugs: string[], citiesName: string[], t: TFunction): ICityDetail[] => {
  if (citiesName.length !== slugs.length) return [];

  return citiesName.map((name, index) => {
    const slug: string = slugs[index];
    const url = slug ? `${fullBasePath}${t<string>("routes:worksBySlug", { categorySlug: slug })}` : null;
    const title = transformSlugUrlToTitle(slug);
    const titleAlternative: string = t<string>("jobOffers:offer.locationTitle", { city: name });

    return { slug, url, title: title ? title : titleAlternative, name };
  });
};
