import { forCompanies, fullBasePath, magnetoHomeNext } from "@constants";

export type TOptions = {
  tag: string;
  url: string;
  baseUrl: string;
  isExternal: boolean;
};

export const OPTIONS: TOptions[] = [
  {
    tag: "notFound:menu.registerResume",
    url: "",
    baseUrl: "",
    isExternal: false
  },
  {
    tag: "notFound:menu.login",
    url: "",
    baseUrl: "",
    isExternal: false
  },
  {
    tag: "notFound:menu.third",
    url: magnetoHomeNext,
    baseUrl: "",
    isExternal: true
  },
  {
    tag: "notFound:menu.fifth",
    url: "routes:byCity",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "notFound:menu.sixth",
    url: "routes:searchHit",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "notFound:menu.seventh",
    url: "routes:jobs",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "notFound:menu.eighth",
    url: forCompanies,
    baseUrl: "",
    isExternal: true
  }
];
