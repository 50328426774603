import React from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { TFunction, withTranslation } from "next-i18next";
import { isNull } from "ramda-adjunct";
import { Menu } from "antd";
import { PROFILE_HEADER_SCHEMA } from "@schemas/layout/header/profileHeader.schema";
import { loggedUserHost } from "@constants/environment.constant";
import { TProfileHeaderMenu } from "#types/schemas";
import { fillUrlUtms } from "@utils/url";
import styles from "./menuProfile.module.scss";

type BaseProps = {
  t: TFunction;
};

const MenuComponent: React.FC<BaseProps> = ({ t }: BaseProps) => {
  const { query } = useRouter();

  const displayLabel = (name: string, title: string, isLink: boolean) => {
    if (isLink)
      return (
        <Link href={fillUrlUtms(`${loggedUserHost}${name}`, query)} passHref>
          <a>{t<string>(title)}</a>
        </Link>
      );
    return t<string>(title);
  };

  const displayChildren = (options: TProfileHeaderMenu[]) => {
    return options.map(({ title, name }, index) => ({
      label: displayLabel(name, title, true),
      key: `${name}-${index}`
    }));
  };

  return (
    <Menu
      mode={"horizontal"}
      className={styles["profile-header__menu"]}
      items={PROFILE_HEADER_SCHEMA.map(({ subMenu = null, title, icon: MenuIcon, name }, index) => {
        const isLink = isNull(subMenu);
        return {
          key: `${name}-${index}`,
          label: displayLabel(name, title, isLink),
          children: !isLink ? displayChildren(subMenu) : [],
          icon: <MenuIcon />,
          popupOffset: [0, -15]
        };
      })}
    />
  );
};

export default withTranslation("profile")(MenuComponent);
