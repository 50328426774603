import React from "react";
import { TRequestSearch } from "#types/filter.core";
import { JobsMainPageProps } from "@pages/empleos";
import { JobsRepository } from "@repositories/jobs.repository";

type TFillContextValueProps = {
  jobsRepository: typeof JobsRepository;
  filtersApplied: TRequestSearch;
  setContextValue: React.Dispatch<React.SetStateAction<Omit<JobsMainPageProps, "t">>>;
};

export const fillJobsContextValue = async ({
  jobsRepository,
  setContextValue,
  filtersApplied
}: TFillContextValueProps) => {
  try {
    const searchResponse = await jobsRepository.search(filtersApplied);
    const selectedJob = searchResponse.rows[0] || null;
    const similarVacancies = (selectedJob && (await jobsRepository.getSimilarVacancies(selectedJob.id))) || [];
    setContextValue((current) => ({ ...current, searchResponse, selectedJob, similarVacancies }));
    return searchResponse;
  } catch (error) {
    setContextValue((current) => current);
  }
};
