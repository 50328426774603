import { TProfileHeaderMenu } from "#types/schemas";
import { UserOutlined, UnorderedListOutlined } from "@ant-design/icons";
import { BriefcaseIcon } from "react-line-awesome";

export const PROFILE_HEADER_SCHEMA: TProfileHeaderMenu[] = [
  {
    title: "resume.menu.resume",
    name: "/profile/resume",
    icon: UserOutlined
  },
  {
    title: "resume.menu.postulations.menuName",
    name: "/profile/postulationsd",
    icon: UnorderedListOutlined,
    subMenu: [
      {
        title: "resume.menu.postulations.active",
        name: "/profile/postulations/active"
      },
      {
        title: "resume.menu.postulations.inactive",
        name: "/profile/postulations/inactive"
      }
    ]
  },
  {
    title: "resume.menu.jobs.menuName",
    name: "/profile/jobs",
    icon: BriefcaseIcon,
    subMenu: [
      {
        title: "resume.menu.jobs.suggested",
        name: "/profile/jobs/suggested"
      },
      {
        title: "resume.menu.jobs.saved",
        name: "/profile/jobs/saved"
      },
      {
        title: "resume.menu.jobs.search",
        name: "/profile/jobs/search"
      }
    ]
  }
];
