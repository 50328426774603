import { JobsContext } from "@context/jobs.context";
import { EDeviceTypes } from "@enums/filter.core.enum";
import { useContext, useEffect, useState } from "react";

export function useValueByDevice<M, D>(mobileValue: M, desktopValue: D): M | D {
  const [value, setValue] = useState<M | D>(mobileValue);
  const { device } = useContext(JobsContext);

  useEffect(() => {
    device === EDeviceTypes.desktop && setValue(desktopValue);
  }, [device, desktopValue]);

  return value;
}
