export const renameLandingPath = (
  breadCrumbFromServer: string[],
  companySlug: string,
  companyName: string,
  markSlug?: string,
  markName?: string
): string[] => {
  const processString = (str?: string) =>
    str
      ?.replace(/-/g, " ")
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-z0-9 ]/g, "") || "";

  const formattedSlug = processString(companySlug);
  const normalizedCompanyName = processString(companyName);
  const formattedMarkSlug = processString(markSlug);
  const normalizedMarkName = processString(markName) || markSlug?.replace(/-/g, " ") || "";

  const substitutionMap: { [key: string]: string } = {
    [formattedSlug]: normalizedCompanyName,
    [formattedMarkSlug]: normalizedMarkName
  };

  return breadCrumbFromServer.map((crumb) => {
    const normalizedCrumb = crumb.toLowerCase();
    return substitutionMap[normalizedCrumb] || crumb;
  });
};
