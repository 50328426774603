import React from "react";
import { Layout } from "antd";
import HeaderByType from "./headerByType/component";
import FooterByType from "./footerByType/component";
import { TMainContent } from "#types/jobs";
import { EDeviceTypes } from "@enums/filter.core.enum";

type Props = {
  children: React.ReactNode;
  type?: TMainContent;
  device?: EDeviceTypes;
};

function MainContent({ children, device = EDeviceTypes.desktop, type = "general" }: Props) {
  return (
    <Layout>
      <HeaderByType type={type} device={device} />
      {children}
      <FooterByType type={type} />
    </Layout>
  );
}

export default MainContent;
