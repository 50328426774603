import { take } from "ramda";
import { isNonEmptyArray } from "ramda-adjunct";

type BaseProps = Array<string>;

export const getCitiesLabel = (cities: BaseProps) => {
  const limit = 5;
  let res = "";

  if (isNonEmptyArray(cities)) {
    res += take(limit, cities).join(" - ");
    const surplus: number = cities.length - limit;

    if (surplus > 0) {
      res += ` (+${surplus})`;
    }
  }

  return res;
};
