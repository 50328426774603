import React, { FC, Fragment, ReactNode } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { SOCIAL_MEDIAL_MENU } from "@schemas/searchHit";
import { capitalizeAll } from "@utils/strings";
import { fillUrlUtms } from "@utils/url";
import tictok from "@public/assets/icons/tiktok.svg";
import facebook from "@public/assets/icons/facebook.svg";
import style from "./iconsSocialMedia.module.scss";

const getImage = (tag: string) => {
  if (tag === "tiktok") return tictok;
  if (tag === "facebook") return facebook;
};

const anchor = (icon: ReactNode, title: string, href: string) => (
  <a className={style.item} href={href} title={capitalizeAll(title)} target="_blank" rel="noreferrer">
    {icon}
  </a>
);

type Props = {
  size?: number;
};

const IconsSocialMedia: FC<Props> = ({ size = 18 }) => {
  const { query } = useRouter();

  return (
    <Fragment>
      {SOCIAL_MEDIAL_MENU.map(({ tag, icon, url }, i) => {
        const btnIcon = icon ? (
          <i className={icon} style={{ fontSize: `${size * 1.4}px` }} />
        ) : (
          <Image src={getImage(tag)} alt={tag} width={size} height={size} loading="lazy" />
        );
        return <Fragment key={`${tag}-${i}`}>{anchor(btnIcon, tag, fillUrlUtms(url, query))}</Fragment>;
      })}
    </Fragment>
  );
};

export default IconsSocialMedia;
