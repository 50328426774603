import React, { FC, Fragment, useCallback, useContext } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import { TFunction, withTranslation } from "next-i18next";
import { AuthContext } from "@context";
import { CreateAccount } from "@components/signUp/createAccountButton/component";
import LoginLink from "@components/common/loginLink/wrap";
import { TPagesLinks } from "@schemas/footer";
import { fillUrlUtms } from "@utils/url";

type Props = {
  t: TFunction;
  links: TPagesLinks[];
  style: {
    readonly [key: string]: string;
  };
};

const GeneralLinks: FC<Props> = ({ links, style, t }) => {
  const { isAuthenticated } = useContext(AuthContext);
  const { query } = useRouter();

  const urlCompanyPages = useCallback(
    ({ routePath, baseUrl }: TPagesLinks) => {
      const path = t<string>(routePath);
      return fillUrlUtms(`${baseUrl}${path}`, query);
    },
    [t, query]
  );

  return (
    <Fragment>
      {links.map((item, i) => {
        if ((item.tag.includes("registerResume") || item.tag.includes("login")) && isAuthenticated)
          return <Fragment key={`${item.tag}-${i}`} />;
        if (item.tag.includes("registerResume"))
          return (
            <CreateAccount
              key={`${item.tag}-${i}`}
              t={t}
              name={t<string>(item.tag)}
              propStyle={style.btnRegister}
              withIcon={false}
            />
          );
        if (item.tag.includes("login"))
          return (
            <LoginLink key={`${item.tag}-${i}`} propStyle={style.link} withIcon={false} name={t<string>(item.tag)} />
          );

        const urlWithUtms = urlCompanyPages(item);
        const anchor = (
          <a className={style.link} href={urlWithUtms} key={`${item.tag}-${i}`} title={t<string>(item.tag)}>
            {t<string>(item.tag)}
          </a>
        );
        return item?.isExternal ? (
          anchor
        ) : (
          <Link key={`link_${i}`} href={urlWithUtms} passHref>
            {anchor}
          </Link>
        );
      })}
    </Fragment>
  );
};

export default withTranslation(["routes", "footer"])(GeneralLinks);
