import React, { Fragment, useCallback, useMemo, useState } from "react";
import dynamic from "next/dynamic";
import { TFunction, withTranslation } from "next-i18next";
import { Layout } from "antd";
import { useIsWidgetToApply } from "@hooks";
import { EWidgetType } from "@enums";
import HeaderButtons from "../children/buttons.wrap";
import LinkLogo from "../children/searchHit/linkLogo/component";
// import PickSearchHit from "./children/searchHit/pickSearchHit.component";
// import { PICK_SEARCH_HIT } from "@schemas/searchHit";
import styles from "./searchHitHeaderType.module.scss";

const { Header } = Layout;
const headerClass = "job-offers-header";
type Props = { t: TFunction };

const SearchHitHeader: React.FC<Props> = () => {
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const isWidgetIframe = useIsWidgetToApply(EWidgetType.iframe);

  const onClose = useCallback(() => setShowMenu((show) => !show), [setShowMenu]);

  const displayBurgerIcon = useMemo(() => {
    if (isWidgetIframe) return <Fragment />;
    const Burger = dynamic(() => import("../children/burger.component"));
    return <Burger isOpen={showMenu} handleShow={onClose} size="22px" color="#2e2d2d" thick="2px" />;
  }, [isWidgetIframe, onClose, showMenu]);

  const displayMenuSearchHit = useMemo(() => {
    if (showMenu) {
      const MenuSearchHit = dynamic(() => import("../children/searchHit/menuSearchHit/component"));
      return <MenuSearchHit open={showMenu} onClose={onClose} />;
    }
    return <Fragment />;
  }, [showMenu, onClose]);

  return (
    <Header className={`${styles[headerClass]} ${styles.header}`}>
      <div className={styles[`${headerClass}__content-container`]}>
        {displayBurgerIcon}
        <LinkLogo margin="15px" />
      </div>
      {/* <PickSearchHit options={PICK_SEARCH_HIT} /> */}
      <HeaderButtons />
      {displayMenuSearchHit}
    </Header>
  );
};

export default withTranslation("searchHit")(SearchHitHeader);
