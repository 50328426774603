import { fullBasePath, loggedUserHost } from "@constants";

type TOptions = {
  tag: string;
  url: string;
  baseUrl: string;
  isExternal: boolean;
};

export const NORESULTSOPTIONS: TOptions[] = [
  {
    tag: "notFound:menu.nine",
    url: "routes:resume",
    baseUrl: `${loggedUserHost}`,
    isExternal: false
  },
  {
    tag: "notFound:menu.fifth",
    url: "routes:byCity",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "notFound:menu.ten",
    url: "routes:bySector",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  },
  {
    tag: "notFound:menu.fourth",
    url: "routes:byCompany",
    baseUrl: `${fullBasePath}`,
    isExternal: false
  }
];
