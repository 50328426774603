import { useMemo } from "react";
import { useRouter } from "next/router";
import { EWidgetType } from "@enums";
import { useTranslation } from "next-i18next";

export const useIsWidgetToApply = (type: EWidgetType): boolean => {
  const { query, pathname } = useRouter();
  const { t } = useTranslation("routes");

  const isWidgetType = useMemo(() => {
    return (
      query.widgetType === type &&
      (pathname === t<string>("routes:jobs") || pathname.startsWith(t<string>("routes:works")))
    );
  }, [pathname, query, type, t]);

  return isWidgetType;
};
