import { TAsideMenu } from "#types/aside";
import { HeaderDrawerTabs, ListIconLink, ListMenuItems } from "magneto365.ui";
import { loginUrl } from "@constants";
import { MAGNETO_HEADER_DRAWER_MENU_SCHEMA } from "../../../schemas/layout/header/magnetoUIHeader.schema";
import { TSocialMediaSearchHit } from "../../../schemas/searchHit";
import style from "./asideMenu.module.scss";
const blockClassName = "aside-magneto-menu";

const AsideMenu: React.FC<TAsideMenu> = ({ t, headerDrawerProps, listMenuProps, listIcon }: TAsideMenu) => {
  return (
    <aside className={style[blockClassName]}>
      <HeaderDrawerTabs {...headerDrawerProps} />
      <div className={style["menu-container"]}>
        <ListMenuItems {...listMenuProps} />
        <ListIconLink listIcon={listIcon as TSocialMediaSearchHit[]} size={34} spacing={20} />
      </div>
      <a href={loginUrl}>{t<string>(MAGNETO_HEADER_DRAWER_MENU_SCHEMA.loginButton.buttonText)}</a>
    </aside>
  );
};

export default AsideMenu;
