import { useCallback, useContext, useEffect, useState } from "react";
import { TFunction } from "next-i18next";
import { useRouter } from "next/router";

import { JobsContext, LandingContext } from "@contextjobs.context";
import { WidgetRepository } from "@repositorieswidget.repository";
import { loadSdk, loadStyle } from "@utilscandidate";
import { setUrlState } from "@utilsurl";

type Props = {
  t: TFunction;
  onClose?: React.Dispatch<React.SetStateAction<boolean>>;
};

const useSignupWidget = ({ t, onClose }: Props) => {
  const [loading, setLoading] = useState<boolean>(false);

  const jobContext = useContext(JobsContext);
  const landingContext = useContext(LandingContext);
  const router = useRouter();

  const onClickHandle = useCallback(
    async (event: Event) => {
      event.preventDefault();
      onClose?.(false);

      setLoading(true);
      const profileAppRoot = window.document.getElementById("magneto-signup-v2-modal");
      profileAppRoot?.setAttribute("data-modal-status", "open");

      /*
        Must set the url before loading the widget
        to set relevant query params in reducer
      */
      setUrlState({
        companySlug: landingContext.landingPage?.mainCompanySlug,
        jobSlug: jobContext.onDetailJobSection ? jobContext.selectedJob?.jobSlug : "",
        action: jobContext?.jobDetailAction,
        route: router.route,
        t
      });

      try {
        const response = await WidgetRepository.getSignUpScripts();
        await loadStyle("magneto.ui.signup", response.payloadStyle);
        await loadSdk("magneto.sdk.signup", response.payloadScript);
      } catch (error) {
        console.error("Error loading signup scripts:", error);
      } finally {
        setLoading(false);
      }
    },
    [
      onClose,
      landingContext.landingPage?.mainCompanySlug,
      jobContext.onDetailJobSection,
      jobContext.selectedJob?.jobSlug,
      jobContext?.jobDetailAction,
      router.route,
      t
    ]
  );

  useEffect(() => {
    const signUpButton: NodeListOf<Element> = window.document.querySelectorAll('body a[href="magneto://signup"]');
    signUpButton.forEach((item) => {
      item.addEventListener("click", onClickHandle);
    });

    return () => {
      signUpButton.forEach((item) => {
        item.removeEventListener("click", onClickHandle);
      });
    };
  }, [onClickHandle]);

  return { loading, onClickHandle };
};

export default useSignupWidget;
