type Props = {
  prevSlug: string | undefined;
  navigationComplete: () => void;
};

export const reloadMainProps = ({ prevSlug, navigationComplete }: Props): void => {
  const currentSlug = window.location.pathname
    .split("/")
    .filter((segment) => segment !== "")
    .pop();
  if (prevSlug === currentSlug) return;
  navigationComplete();
};
