import React, { FC, useState } from "react";
import { TFunction, withTranslation } from "next-i18next";
import { INTERNAL_LINKING } from "@schemas/internalLinking/linkingList";
import Link from "next/link";
import classNames from "classnames";
import styles from "./component.module.scss";

const blockClassName = "internal-linking";

type Props = {
  t: TFunction;
};

const InternalLinking: FC<Props> = ({ t }) => {
  const [loadFullList, setLoadFullList] = useState(false);

  const handleLoadClick = () => {
    setLoadFullList(() => !loadFullList);
  };

  return (
    <div className={styles[`${blockClassName}`]}>
      <div className={styles[`${blockClassName}__content`]}>
        <h2>{t<string>("footer:internalLinking")}</h2>
        <div className={classNames(styles["generated-links__content"], { [styles["loadFullList"]]: loadFullList })}>
          {INTERNAL_LINKING.map(({ tag, url }, i) => (
            <div key={`${i}-${tag}`}>
              <Link key={`${i}-${tag}`} href={url} passHref>
                <a className={styles.links}>
                  {tag} <i className="las la-angle-right" />
                </a>
              </Link>
            </div>
          ))}
        </div>
      </div>
      <span className={styles.loadMoreLinksBtn} onClick={handleLoadClick}>
        {t<string>("footer:showFullList", {
          action: loadFullList ? t<string>("footer:showLess") : t<string>("footer:showMore")
        })}
      </span>
    </div>
  );
};

export default withTranslation()(InternalLinking);
