import { useContext, useMemo } from "react";
import { TFunction } from "next-i18next";
import { IFrequentSearch, IVacancies } from "magneto365.ui";
import { JobsContext, LandingContext } from "@context/jobs.context";
import ContentDynamic from "@components/common/contentDynamic/contentDynamic.wrap";
import { getCitiesLabel, getFormatDistanceToNow, getFormattedExperience, getFormattedSalary } from "@utils/jobOffers";
import { convertedEmploymentType } from "@utils/dataStructured/convertedEmploymentType.util";

type TProps = {
  t: TFunction;
  showFrequentSearch?: boolean | null | undefined;
};

type TUseMagnetoJobsReturn = {
  vacantProps: IVacancies[];
  frequentSearchProps: IFrequentSearch;
  selectedJob: IVacancies;
};

const useMagnetoJobs = ({ t, showFrequentSearch = true }: TProps): TUseMagnetoJobsReturn => {
  const { searchResponse, internalLinking, selectedJob } = useContext(JobsContext);
  const { landingPage } = useContext(LandingContext);
  const jobOffersData = searchResponse.rows.filter((row) => row.jobSlug);
  const addComma = ", ";

  const vacantProps = useMemo(() => {
    const vacantsData = jobOffersData.map(
      ({
        title,
        companySlug,
        educationLevel,
        experienceMonthsNumber,
        cities,
        companyLogo,
        companyName,
        salary,
        minSalary,
        maxSalary,
        toAgree,
        publishDate,
        contractType,
        currencyCode,
        inmediateHiring,
        id,
        ...rest
      }) => ({
        ...rest,
        isCompanyPage: !!landingPage,
        id,
        companyLogo: companyLogo?.url,
        title,
        companyName,
        companySlug,
        formatPublishDate: <ContentDynamic content={getFormatDistanceToNow(publishDate as string, t)} />,
        cities: cities.length > 0 ? [getCitiesLabel(cities)] : "",
        educationLevel:
          educationLevel && educationLevel?.length > 0
            ? educationLevel?.map(({ name }) => name).join(addComma) + "."
            : "",
        experience: experienceMonthsNumber ? getFormattedExperience(experienceMonthsNumber, t) + addComma : "",
        salary: getFormattedSalary({ salary, minSalary, maxSalary, toAgree, currency: currencyCode, t }) + addComma,
        contractType: contractType
          ? t(`jobOffers:contractType.${convertedEmploymentType(contractType)?.toLowerCase().replace("_", "")}`)
          : "",
          urgent: inmediateHiring && t('jobOffers:offer.urgent')
      })
    );

    return vacantsData;
  }, [jobOffersData, landingPage, t]);

  const frequentSearchProps = useMemo(() => {
    return {
      searchHeading: t<string>("footer:internalLinking"),
      searchList: internalLinking,
      showLess: t("footer:showFullList", { action: t<string>("footer:showLess") }),
      showMore: t("footer:showFullList", { action: t<string>("footer:showMore") })
    };
  }, [t, internalLinking]);

  return {
    vacantProps: vacantProps as unknown as IVacancies[],
    selectedJob: selectedJob as unknown as IVacancies,
    frequentSearchProps: { ...frequentSearchProps, showFrequentSearch: Boolean(showFrequentSearch) }
  };
};

export default useMagnetoJobs;
