import { TFunction } from "next-i18next";
import { useRouter } from "next/router";
import { useContext, useMemo } from "react";
import { AuthContext } from "../../context";
import { ENotFoundType } from "../../enums";
import { OPTIONS } from "../../schemas/notFound";
import { fillUrlUtms } from "../../utils/url";

type TProps = {
  t: TFunction;
  type: string;
};

const useMagnetoNotFoundVacant = ({ t, type = ENotFoundType.T404 }: TProps) => {
  const { query } = useRouter();
  const { isAuthenticated } = useContext(AuthContext);
  const titleNotFound = t<string>(`notFound:${type}.title`);
  const firstDescription = t<string>(`notFound:${type}.description-1`);
  const secondDescription = t<string>(`notFound:${type}.description-2`);

  const filteredOptions = isAuthenticated ? OPTIONS.slice(2) : OPTIONS;

  const notFoundProps = useMemo(() => {
    titleNotFound;
    firstDescription;
    secondDescription;
    filteredOptions;
    return {
      titleNotFound,
      firstDescription,
      secondDescription,
      filteredOptions: filteredOptions.map(({ tag, url, baseUrl, isExternal }) => ({
        tag: t(tag),
        url: isExternal ? fillUrlUtms(url, query) : fillUrlUtms(`${baseUrl}${t<string>(url)}`, query),
        baseUrl,
        isExternal
      }))
    };
  }, [filteredOptions, firstDescription, query, secondDescription, t, titleNotFound]);

  return { notFoundProps };
};
export default useMagnetoNotFoundVacant;
