import { TFunction } from "next-i18next";
import { language } from "@constants/environment.constant";

type GetFormattedSalaryProps = {
  t: TFunction;
  salary?: number | null;
  minSalary?: number | null;
  maxSalary?: number | null;
  toAgree?: boolean | null;
  currency?: string;
};

export const getFormattedSalary = ({
  salary,
  minSalary,
  maxSalary,
  toAgree,
  currency,
  t
}: GetFormattedSalaryProps): string | undefined => {
  if (toAgree) return t("jobOffers:formatted.toBeAgreed");

  const args = (currency && { maximumSignificantDigits: 9, style: "currency", currency }) || {};

  if (typeof minSalary === "number" && typeof maxSalary === "number") {
    if (minSalary === 0) return getFormattedSalary({ salary: maxSalary, currency, t });
    if (minSalary === maxSalary) return getFormattedSalary({ salary: minSalary, currency, t });
    if (minSalary > maxSalary) return getFormattedSalary({ minSalary: maxSalary, maxSalary: minSalary, currency, t });
    const min = new Intl.NumberFormat(language, args).format(minSalary);
    const max = new Intl.NumberFormat(language, args).format(maxSalary);
    return t("jobOffers:formatted.salaryRange", { min, max });
  }

  if (typeof salary === "number") return new Intl.NumberFormat(language, args).format(salary);

  if (typeof minSalary === "number") {
    const min = new Intl.NumberFormat(language, args).format(minSalary);
    return t("jobOffers:formatted.salaryRangeFrom", { min });
  }

  if (typeof maxSalary === "number") {
    const max = new Intl.NumberFormat(language, args).format(maxSalary);
    return t("jobOffers:formatted.salaryRangeTo", { max });
  }
};
